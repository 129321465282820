import React from 'react';
import Layout from '../components/Layout';
import kampaamo from '../images/kampaamo.jpeg';

const Services = () => {
    return (
        <Layout>
            <section className="hero section-padding" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="info-title">
                                <h1>Palvelut ja hinnasto</h1>
                            </div>
                            <div className="herobg heroimage2"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="parturi-kampaamo" className="py-3">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <h2>Parturi-kampaamo palvelut</h2>
                            <p>
                                Meiltä saat kampaukset, värjäykset, permanentit,
                                keratiini suoristuksen, parturi palvelut sekä
                                jalkahoitoa yli 10-vuoden kokemuksella itiksen
                                kauppakeskusesta.
                            </p>
                            <p>
                                Meillä on käytössä kanta-asiakaskortti, mikä
                                oikeuttaa joka viidennelle hiustenleikkaukselle
                                10 euron alennuksen.
                            </p>
                            <p>
                                Lisätietoa numerosta{' '}
                                <strong>
                                    <a href="0503801400">050 38 01 400</a>
                                </strong>
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-xs-12 col-md-10">
                            <div className="row">
                                <div className="col-4">
                                    <picture>
                                        <img
                                            src={kampaamo}
                                            alt="Kampaamo palveluiden koot"
                                            className="img-fluid"
                                        />
                                    </picture>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Kampaamo palvelut
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Kapaamo palvelut</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Hiusten leikkaus alk.</td>
                                                <td>35€</td>
                                                <td>40€</td>
                                                <td>40€</td>
                                                <td>50€</td>
                                                <td>+ 5-20€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Hiusten tasaus (suora linja)
                                                    alk.
                                                </td>
                                                <td>25-30€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>+ Mallinmuutos</td>
                                                <td>5-10€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Otsatukka</td>
                                                <td>10-15€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Hiusten leikkaus + kiharat/
                                                    suoristus
                                                </td>
                                                <td>50€</td>
                                                <td>55€</td>
                                                <td>60€</td>
                                                <td>65€</td>
                                                <td>70€</td>
                                            </tr>
                                            <tr>
                                                <td>+ Hiusten pesu</td>
                                                <td>5€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Hiusten pesu ja kuivaus</td>
                                                <td>10€</td>
                                                <td>15€</td>
                                                <td>20€</td>
                                                <td>25€</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Hiusten pesu ja föönaus</td>
                                                <td>20€</td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>40€</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Hiusten pesu ja kampaus</td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>40€</td>
                                                <td>45€</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Kiharat alk.</td>
                                                <td>30€</td>
                                                <td>35€</td>
                                                <td>45€</td>
                                                <td>50€</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Suoristus alk.</td>
                                                <td>20€</td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>40€</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Juhlakampaus alk.</td>
                                                <td>50€/h</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Letti alk.</td>
                                                <td>20€</td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>35€</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20">Väri</h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Väri</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Tyvi (max. 3cm) ilman
                                                    hiusten leikkausta
                                                </td>
                                                <td>55€</td>
                                                <td>55€</td>
                                                <td>65€</td>
                                                <td>65€</td>
                                                <td>75€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tyvi (max. 3cm) sis. hiusten
                                                    leikkauksen alk.
                                                </td>
                                                <td>70-75€</td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>100€</td>
                                                <td>110€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tyvi + suoristus/kiharat
                                                    ilman hiusten leikkausta
                                                </td>
                                                <td>60€</td>
                                                <td>65€</td>
                                                <td>75€</td>
                                                <td>85€</td>
                                                <td>95€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Väri sis. hiusten
                                                    leikkauksen
                                                </td>
                                                <td>75€</td>
                                                <td>85€</td>
                                                <td>95€</td>
                                                <td>120-150€</td>
                                                <td>160€</td>
                                            </tr>
                                            <tr>
                                                <td>Moniväri alk.</td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>100€</td>
                                                <td>120-140€</td>
                                                <td>150-170€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Värin virkistys / Kiiltokäsittely / Sävy
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Värin virkistys /
                                                    Kiiltokäsittely / Sävy
                                                </th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Sis. hiusten leikkauksen
                                                </td>
                                                <td>45€</td>
                                                <td>50€</td>
                                                <td>60€</td>
                                                <td>70€</td>
                                                <td>80€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Ilman hiusten leikkausta
                                                </td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>40€</td>
                                                <td>50€</td>
                                                <td>60€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Vaalennus (sis. sävyn)
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Vaalennus (sis. sävyn)</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Tyvi (max. 2cm) ilman
                                                    hiusten leikkausta
                                                </td>
                                                <td>65€</td>
                                                <td>75€</td>
                                                <td>85€</td>
                                                <td>105€</td>
                                                <td>135€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tyvi (max. 2cm) sis. hiusten
                                                    leikkauksen
                                                </td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>100€</td>
                                                <td>120€</td>
                                                <td>150€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Vaalennus ilman hiusten
                                                    leikkausta
                                                </td>
                                                <td>75€</td>
                                                <td>90€</td>
                                                <td>110€</td>
                                                <td>150€</td>
                                                <td>190€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Vaalennus sis. hiusten
                                                    leikkauksen alk.
                                                </td>
                                                <td>90€</td>
                                                <td>100-120€</td>
                                                <td>130-160€</td>
                                                <td>180-200€</td>
                                                <td>220-260€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Raidat (sis. hiusten leikkauksen ja
                                        föön.)
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Raidat</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Tyviraidat</td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>120€</td>
                                                <td>150-200€</td>
                                                <td>250€</td>
                                            </tr>
                                            <tr>
                                                <td>Raidat (puoli päätä)</td>
                                                <td>75€</td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>120€</td>
                                                <td>140€</td>
                                            </tr>
                                            <tr>
                                                <td>Raidat (koko pää) alk.</td>
                                                <td>80€</td>
                                                <td>90-110€</td>
                                                <td>120-140€</td>
                                                <td>150-200€</td>
                                                <td>250€</td>
                                            </tr>
                                            <tr>
                                                <td>+ Suoristus/ kiharat</td>
                                                <td>5€</td>
                                                <td>10€</td>
                                                <td>15€</td>
                                                <td>20€</td>
                                                <td>25€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Hiusten hoito
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Hiusten hoito</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Öljy / Tehohoito / Mask</td>
                                                <td>25€</td>
                                                <td>30€</td>
                                                <td>35€</td>
                                                <td>40€</td>
                                                <td>50€</td>
                                            </tr>
                                            <tr>
                                                <td>Plazma</td>
                                                <td>30€</td>
                                                <td>40€</td>
                                                <td>50€</td>
                                                <td>60€</td>
                                                <td>70€</td>
                                            </tr>
                                            <tr>
                                                <td>Keratiinisuoristus</td>
                                                <td>150€</td>
                                                <td>250€</td>
                                                <td>350€</td>
                                                <td>400€</td>
                                                <td>500€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Permanentti
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Permanentti</th>
                                                <th>S</th>
                                                <th>M</th>
                                                <th>L</th>
                                                <th>XL</th>
                                                <th>Extra paksut/ pitkät</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Permanentti ilman hiusten
                                                    leikkausta
                                                </td>
                                                <td>65€</td>
                                                <td>75€</td>
                                                <td>85€</td>
                                                <td>100€</td>
                                                <td>150-170€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Permanentti sis. hiusten
                                                    leikkauksen
                                                </td>
                                                <td>80€</td>
                                                <td>90€</td>
                                                <td>100€</td>
                                                <td>120€</td>
                                                <td>200€</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Permanentti sis. hiusten
                                                    leikkauksen ja värin
                                                </td>
                                                <td>95€</td>
                                                <td>110€</td>
                                                <td>130€</td>
                                                <td>170€</td>
                                                <td>190-250€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Ripset ja Kulmat
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Ripset ja kulmat</td>
                                                <td>25€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Ripsien värjäys</td>
                                                <td>10€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Kulmien värjäys</td>
                                                <td>10€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Kulmien muotoilu</td>
                                                <td>10€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Kulmien värjäys ja muotoilu
                                                    alk.
                                                </td>
                                                <td>15-20€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <h2 className="mt-20 mb-20 ls2">
                                        Parturi palvelut
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Hiusten leikkaus</td>
                                                <td>25€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Hiusten leikkaus saksilla
                                                </td>
                                                <td>30€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Jenkkisiili</td>
                                                <td>30€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Mallin muutos</td>
                                                <td>30€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Extra pitkät</td>
                                                <td>30€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Koneleikkaus (1:llä terällä)
                                                </td>
                                                <td>15€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Koneleikkaus (2-4:llä
                                                    terällä)
                                                </td>
                                                <td>20€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Parran-ajo / muotoilu alk.
                                                </td>
                                                <td>10-20€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Väri ja hiusten leikkaus
                                                </td>
                                                <td>65€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Väri ilman hiusten
                                                    leikkausta
                                                </td>
                                                <td>50€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Raidat / Permanentti sis.
                                                    hiusten leikkauksen alk.
                                                </td>
                                                <td>65-75€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Raidat / Permanentti ilman
                                                    hiusten leikkausta
                                                </td>
                                                <td>50€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Lasten hiusten leikkaus
                                                    (alle 12v)
                                                </td>
                                                <td>20€</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-8">
                                    <div className="intro-description">
                                        <h2>Kanta-asiakkuus</h2>
                                        <p>
                                            Meillä on käytössä
                                            kanta-asiakaskortti, mikä oikeuttaa
                                            joka viidennelle
                                            hiustenleikkaukselle 10 euron
                                            alennuksen.
                                        </p>
                                        <p>
                                            Lisätietoa numerosta{' '}
                                            <strong>
                                                <a href="0503801400">
                                                    050 38 01 400
                                                </a>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dsn-quote py-2">
                <div className="container">
                    <div className="row direction-column sticky-container">
                        <div className="col-md-1">
                            <div className="quote-content">
                                <svg
                                    className="icon--quote"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 55 55"
                                    role="img"
                                    aria-label="Quote Icon"
                                >
                                    <path
                                        className="ring"
                                        d="M27.5,3C41,3,52,14,52,27.5S41,52,27.5,52S3,41,3,27.5S14,3,27.5,3 M27.5,0C12.3,0,0,12.3,0,27.5
                                    S12.3,55,27.5,55S55,42.7,55,27.5S42.7,0,27.5,0L27.5,0z"
                                    ></path>
                                    <path
                                        className="marks"
                                        d="M26.9,22.1c-3.4,0.4-6,2.4-6,5.8c0,0.4,0.2,0.4,0.4,0.2c0.5-0.5,1.4-0.7,2.2-0.7c1.6,0,3,1.1,3,3.3
                                    c0,1.8-1.6,3.3-3.4,3.3c-2.7,0-4-2.2-4-5.1c0-5.1,4.3-7.3,7.6-7.5L26.9,22.1z M36,22.1c-3.3,0.4-5.9,2.4-5.9,5.8
                                    c0,0.4,0.2,0.4,0.3,0.2c0.5-0.5,1.4-0.7,2.2-0.7c1.6,0,3.1,1.1,3,3.3c0,1.8-1.6,3.3-3.4,3.3c-2.7,0-4.1-2.2-4.1-5.1
                                    c0-5.1,4.3-7.3,7.6-7.5L36,22.1z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <p className="text-center">
                                "Koko perheen viihtyisä ja lämminhenkinen
                                parturi-kampaamo ja kauneushoitola, Tervetuloa!"
                            </p>

                            <h3 className="text-center">
                                Yli 10-vuoden kokemuksella.
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Services;
